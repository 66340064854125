import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '@oup/shared-front-end/src/components/Checkbox/Checkbox.js';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage/ValidationMessage.js';
import { useSelector, useDispatch } from 'react-redux';
import { PLATFORMS } from '../../../../sharedNodeBrowser/constants.js';
import HubLabel from '../../../components/HubLabel/HubLabel.js';
import styles from './ConfirmationSingleItem.scss';
import RadioInput from '../../../components/RadioInput/RadioInput.js';
import TextInput from './TextInput.js';
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu.js';
import Toggle from './Toggle.js';
import IconErrorCircle from './icon-error-circle-24.svg';
import VSTLinksContainer from './VSTLinks.js';
import {
  showProductDetailsModal,
  setConfirmedProducts,
  setConfirmedProductsBackup,
  setStartAndEndPage
} from '../../../redux/actions/ngiContentSelectorActions.js';

const VST_ROOT_LEVEL = 0;
const VST_UNIT_LEVEL = 1;
const VST_LESSON_LEVEL = 2;

const START_PAGE = 'START_PAGE_SELECTOR';
const END_PAGE = 'END_PAGE';

function ConfirmationSingleItem({
  isChecked,
  showCheckbox,
  alwaysShowToggles,
  product,
  onRadioChange,
  onCheckboxClick,
  onAddContentItemsToggleChange,
  onAddGradeItemsToggleChange,
  isAddDeeplinksChecked,
  isAddGradesChecked
}) {
  const dispatch = useDispatch();
  const { lmsConfig, confirmedProducts, confirmedProductsBackup } = useSelector(state => state.ngiContentSelector);
  const { products: openProducts } = useSelector(state => state.getOpenProducts);

  const targetUserType = product.userType === 'TEACHER' ? 'Teacher material' : 'Student material';
  const isFromPack = product.fromPack === 'Yes';
  const isAddContentItemsToggleVisible = [PLATFORMS.VST, PLATFORMS.OLB].includes(product.platform);
  const isAddGradeItemsToggleVisible =
    [PLATFORMS.VST, PLATFORMS.OLB].includes(product.platform) &&
    product.isAssignable === 'true' &&
    product.userType !== 'TEACHER';
  const linkDescription = product.platform === PLATFORMS.VST ? 'Add links to activities' : 'Add links to pages';

  const handleOnCheckboxClick = () => {
    onCheckboxClick(product);
  };

  const handleOnRadioChange = () => {
    onRadioChange(product);
  };

  const getFirstIsCheckedValue = (level, targetProduct, unitIndex, lessonIndex) => {
    if (level === VST_ROOT_LEVEL) {
      return targetProduct.vstUnits[0]?.vstLessons[0]?.vstActivities[0].isChecked;
    }
    if (level === VST_UNIT_LEVEL) {
      return targetProduct.vstUnits[unitIndex]?.vstLessons[0]?.isChecked;
    }
    if (level === VST_LESSON_LEVEL) {
      return targetProduct?.vstUnits[unitIndex]?.vstLessons[lessonIndex]?.vstActivities[0]?.isChecked;
    }
    return null;
  };

  const doAllElementsHaveTheSameIsCheckedValue = (value, level, targetProduct, unitIndex, lessonIndex) => {
    // This function helps to identify if all elements has the same value from specific level to the leafs
    let targetValue;
    if (value === null) {
      targetValue = getFirstIsCheckedValue(level, targetProduct, unitIndex, lessonIndex);
    } else {
      targetValue = value;
    }

    if (targetValue === null) return false;

    if (level === VST_ROOT_LEVEL) {
      // Case when class room items is has not the same value as the rest of the elements from tree
      if (
        targetProduct.includeOnlineClassroom !== undefined &&
        targetProduct.includeOnlineClassroom !== getFirstIsCheckedValue(VST_ROOT_LEVEL, targetProduct)
      )
        return false;

      return targetProduct.vstUnits.every(unit =>
        unit.vstLessons.every(lesson => lesson.vstActivities.every(activity => activity.isChecked === targetValue))
      );
    }
    if (level === VST_UNIT_LEVEL) {
      return targetProduct.vstUnits[unitIndex]?.vstLessons.every(lesson =>
        lesson.vstActivities.every(activity => activity.isChecked === targetValue)
      );
    }
    if (level === VST_LESSON_LEVEL) {
      return targetProduct.vstUnits[unitIndex]?.vstLessons[lessonIndex].vstActivities.every(
        activity => activity.isChecked === targetValue
      );
    }
    return false;
  };

  const handleToggle = (value, level, targetProduct, unitIndex, lessonIndex = null) => {
    if (level === VST_UNIT_LEVEL) {
      targetProduct.vstUnits[unitIndex].accordionOpened = !value;
    }
    if (level === VST_LESSON_LEVEL) {
      targetProduct.vstUnits[unitIndex].vstLessons[lessonIndex].accordionOpened = !value;
    }
    return { ...targetProduct };
  };

  const handleChangeAllElements = (value, level, targetProduct, unitIndex, lessonIndex) => {
    let newValue = value;
    if (!doAllElementsHaveTheSameIsCheckedValue(null, level, targetProduct, unitIndex, lessonIndex)) {
      newValue = true;
    }
    if (level === VST_ROOT_LEVEL) {
      targetProduct.vstUnits = targetProduct.vstUnits.map(unit => {
        unit.isChecked = newValue;
        unit.vstLessons = unit.vstLessons.map(lesson => {
          lesson.isChecked = newValue;
          lesson.vstActivities = lesson.vstActivities.map(activity => {
            activity.isChecked = newValue;
            return activity;
          });
          return lesson;
        });
        return unit;
      });
      // Include class room in select all
      if (targetProduct.includeOnlineClassroom !== undefined) {
        targetProduct.includeOnlineClassroom = newValue;
      }
    }
    if (level === VST_UNIT_LEVEL) {
      targetProduct.vstUnits[unitIndex].vstLessons = targetProduct.vstUnits[unitIndex].vstLessons.map(lesson => {
        lesson.isChecked = newValue;
        lesson.vstActivities = lesson.vstActivities.map(activity => {
          activity.isChecked = newValue;
          return activity;
        });
        return lesson;
      });
      targetProduct.vstUnits[unitIndex].isChecked = newValue;
    }
    if (level === VST_LESSON_LEVEL) {
      targetProduct.vstUnits[unitIndex].vstLessons[lessonIndex].vstActivities = targetProduct.vstUnits[
        unitIndex
      ].vstLessons[lessonIndex].vstActivities.map(activity => {
        activity.isChecked = newValue;
        return activity;
      });
      targetProduct.vstUnits[unitIndex].vstLessons[lessonIndex].isChecked = newValue;
    }
    return { ...targetProduct };
  };

  const updateAncestors = targetProduct => {
    targetProduct.vstUnits = targetProduct.vstUnits.map((unit, unitIndex) => {
      unit.vstLessons = unit.vstLessons.map((lesson, lessonIndex) => {
        if (doAllElementsHaveTheSameIsCheckedValue(null, VST_LESSON_LEVEL, targetProduct, unitIndex, lessonIndex)) {
          lesson.isChecked = unit.vstLessons[lessonIndex].vstActivities[0].isChecked;
          lesson.partialSelected = false;
        } else {
          lesson.partialSelected = true;
          lesson.isChecked = true;
        }
        return lesson;
      });
      if (doAllElementsHaveTheSameIsCheckedValue(null, VST_UNIT_LEVEL, targetProduct, unitIndex)) {
        unit.isChecked = unit.vstLessons[0].vstActivities[0].isChecked;
        unit.partialSelected = false;
      } else {
        unit.partialSelected = true;
        unit.isChecked = true;
      }
      return unit;
    });
    if (doAllElementsHaveTheSameIsCheckedValue(null, VST_ROOT_LEVEL, targetProduct)) {
      targetProduct.isChecked = targetProduct.vstUnits[0].vstLessons[0].vstActivities[0].isChecked;
      targetProduct.partialSelected = false;
    } else {
      targetProduct.partialSelected = true;
      targetProduct.isChecked = true;
    }
    return targetProduct;
  };

  const handleOnUnitCheckboxClick = ({
    newCheckedValue,
    selectAll,
    unitIndex,
    lessonIndex,
    activityIndex,
    classRoomCaseEntry,
    toggle
  }) => {
    const previousConfirmedProductsBackup = JSON.parse(JSON.stringify(confirmedProductsBackup));

    dispatch(
      setConfirmedProducts(
        confirmedProducts.map(cProduct => {
          if (cProduct.isbnLaunch === product.isbnLaunch) {
            let updatedProduct = cProduct;
            if (toggle) {
              if (toggle.lessonIndex !== undefined) {
                updatedProduct = handleToggle(
                  toggle.value,
                  VST_LESSON_LEVEL,
                  cProduct,
                  toggle.unitIndex,
                  toggle.lessonIndex
                );
              } else if (toggle.unitIndex !== undefined) {
                updatedProduct = handleToggle(toggle.value, VST_UNIT_LEVEL, cProduct, toggle.unitIndex);
              }
            } else if (activityIndex !== undefined) {
              updatedProduct.vstUnits[unitIndex].vstLessons[lessonIndex].vstActivities[
                activityIndex
              ].isChecked = newCheckedValue;
            } else if (lessonIndex !== undefined) {
              updatedProduct = handleChangeAllElements(
                newCheckedValue,
                VST_LESSON_LEVEL,
                cProduct,
                unitIndex,
                lessonIndex
              );
            } else if (unitIndex !== undefined) {
              updatedProduct = handleChangeAllElements(newCheckedValue, VST_UNIT_LEVEL, cProduct, unitIndex);
            } else if (selectAll) {
              updatedProduct = handleChangeAllElements(newCheckedValue, VST_ROOT_LEVEL, cProduct);
            } else if (classRoomCaseEntry) {
              updatedProduct.includeOnlineClassroom = newCheckedValue;
            }

            updatedProduct = updateAncestors(updatedProduct);
            return JSON.parse(JSON.stringify(updatedProduct));
          }
          return { ...cProduct };
        })
      )
    );
    // Add changes into backup products
    dispatch(
      setConfirmedProductsBackup(
        previousConfirmedProductsBackup.map(cProduct => {
          if (cProduct.isbnLaunch === product.isbnLaunch) {
            let updatedProduct = cProduct;
            if (toggle) {
              if (toggle.lessonIndex !== undefined) {
                updatedProduct = handleToggle(
                  toggle.value,
                  VST_LESSON_LEVEL,
                  cProduct,
                  toggle.unitIndex,
                  toggle.lessonIndex
                );
              } else if (toggle.unitIndex !== undefined) {
                updatedProduct = handleToggle(toggle.value, VST_UNIT_LEVEL, cProduct, toggle.unitIndex);
              }
            } else if (activityIndex !== undefined) {
              updatedProduct.vstUnits[unitIndex].vstLessons[lessonIndex].vstActivities[
                activityIndex
              ].isChecked = newCheckedValue;
            } else if (lessonIndex !== undefined) {
              updatedProduct = handleChangeAllElements(
                newCheckedValue,
                VST_LESSON_LEVEL,
                cProduct,
                unitIndex,
                lessonIndex
              );
            } else if (unitIndex !== undefined) {
              updatedProduct = handleChangeAllElements(newCheckedValue, VST_UNIT_LEVEL, cProduct, unitIndex);
            } else if (selectAll) {
              updatedProduct = handleChangeAllElements(newCheckedValue, VST_ROOT_LEVEL, cProduct);
            } else if (classRoomCaseEntry) {
              updatedProduct.includeOnlineClassroom = newCheckedValue;
            }

            updatedProduct = updateAncestors(updatedProduct);
            return JSON.parse(JSON.stringify(updatedProduct));
          }
          return { ...cProduct };
        })
      )
    );
  };

  const isHubEntry = product.isbnLicense === '-';

  const dropdownActions = {
    menuButtonAriaLabel: 'Content selector dropdown',
    menuPanelAriaLabel: 'Content selector panel menu',
    menuItems: [
      {
        title: 'View product information',
        action: () => {
          const openProduct = openProducts.find(p => p.isbn === product.isbnLaunch);
          dispatch(showProductDetailsModal(openProduct));
        }
      }
    ]
  };

  const addFallbackCover = ev => {
    ev.target.src = '/media/feedback/book-cover.png';
  };

  const currentConfirmedProduct =
    isChecked && confirmedProducts.find(cProduct => cProduct.isbnLaunch === product.isbnLaunch);
  const hasErrors = currentConfirmedProduct.errors && !!Object.values(currentConfirmedProduct.errors).find(e => !!e);

  const validatePage = (PAGE_FIELD_TYPE, value, range) => {
    const parsedValue = parseInt(value, 10);
    if (PAGE_FIELD_TYPE === START_PAGE) {
      if (Number.isNaN(parsedValue)) {
        return {
          type: PAGE_FIELD_TYPE,
          message: 'Value must be a number'
        };
      }

      if (
        parsedValue < range.startPage &&
        currentConfirmedProduct.selectedEndPage <= range.endPage &&
        currentConfirmedProduct.selectedEndPage >= range.startPage
      ) {
        return {
          type: [START_PAGE, END_PAGE],
          message: [`Start page must be higher than ${range.startPage}`, '']
        };
      }
      if (parsedValue < range.startPage) {
        return {
          type: PAGE_FIELD_TYPE,
          message: `Start page must be higher than ${range.startPage}`
        };
      }
      if (parsedValue > range.endPage) {
        return {
          type: PAGE_FIELD_TYPE,
          message: `Start page cannot be higher than ${range.endPage}`
        };
      }
      if (
        parsedValue >= range.startPage &&
        parsedValue > currentConfirmedProduct.selectedEndPage &&
        currentConfirmedProduct.selectedEndPage >= range.startPage &&
        currentConfirmedProduct.selectedEndPage <= range.endPage
      ) {
        return {
          type: [START_PAGE, END_PAGE],
          message: [`Start page cannot be higher than end page`, '']
        };
      }
      if (parsedValue === currentConfirmedProduct.selectedEndPage) {
        return {
          type: [START_PAGE, END_PAGE],
          message: ['', '']
        };
      }
      if (
        parsedValue < currentConfirmedProduct.selectedEndPage &&
        currentConfirmedProduct.selectedEndPage <= range.endPage
      ) {
        return {
          type: [START_PAGE, END_PAGE],
          message: ['', '']
        };
      }
    } else {
      if (Number.isNaN(parsedValue)) {
        return {
          type: PAGE_FIELD_TYPE,
          message: 'Value must be a number'
        };
      }
      if (parsedValue > range.endPage) {
        return {
          type: PAGE_FIELD_TYPE,
          message: `End page must be lower than ${range.endPage}`
        };
      }
      if (parsedValue < range.startPage) {
        return {
          type: PAGE_FIELD_TYPE,
          message: `End page cannot be lower than ${range.startPage}`
        };
      }
      if (
        parsedValue >= range.startPage &&
        parsedValue < currentConfirmedProduct.selectedStartPage &&
        currentConfirmedProduct.selectedStartPage >= range.startPage &&
        currentConfirmedProduct.selectedStartPage <= range.endPage
      ) {
        return {
          type: [START_PAGE, END_PAGE],
          message: ['', `End page cannot be lower than start page`]
        };
      }
      if (parsedValue === currentConfirmedProduct.selectedStartPage) {
        return {
          type: [END_PAGE, START_PAGE],
          message: ['', '']
        };
      }
      if (
        parsedValue > currentConfirmedProduct.selectedStartPage &&
        currentConfirmedProduct.selectedStartPage >= range.startPage
      ) {
        return {
          type: [START_PAGE, END_PAGE],
          message: ['', '']
        };
      }
    }

    return {
      type: PAGE_FIELD_TYPE,
      message: ''
    };
  };

  const handleChangePageSelector = (PAGE_FIELD_TYPE, newValue) => {
    const error = validatePage(PAGE_FIELD_TYPE, newValue, currentConfirmedProduct.olbDetails);

    const payload = {
      isbn: currentConfirmedProduct.isbnLaunch,
      start: currentConfirmedProduct.selectedStartPage,
      end: currentConfirmedProduct.selectedEndPage
    };

    if (typeof error.type === 'string') {
      payload.error = {
        [error.type]: error.message
      };
    } else {
      error.type.forEach((_, idx) => {
        payload.error = { ...payload.error, [error.type[idx]]: error.message[idx] };
      });
    }

    const parsedNewValue = parseInt(newValue, 10);

    if (PAGE_FIELD_TYPE === START_PAGE) {
      payload.start = Number.isNaN(parsedNewValue) ? '' : parsedNewValue;
    } else {
      payload.end = Number.isNaN(parsedNewValue) ? '' : parsedNewValue;
    }
    dispatch(setStartAndEndPage(payload));
    dispatch(
      setConfirmedProductsBackup(
        confirmedProductsBackup.map(bProduct => {
          if (bProduct.isbnLaunch === currentConfirmedProduct.isbnLaunch) {
            bProduct.selectedStartPage = payload.start;
            bProduct.selectedEndPage = payload.end;
            bProduct.errors = { ...(bProduct.errors || {}), ...payload.error };
          }
          return bProduct;
        })
      )
    );
  };

  const totalPageNumber = currentConfirmedProduct.selectedEndPage - currentConfirmedProduct.selectedStartPage + 1;

  return (
    <li className={styles.list}>
      <div className={styles.container}>
        <div className={styles.checkboxImageContainer}>
          {showCheckbox && (
            <div className={styles.checkbox}>
              {lmsConfig.acceptMultiple ? (
                <Checkbox
                  id={product.isbnLaunch}
                  checked={isChecked}
                  label={`select ${product.title}`}
                  value={`select ${product.title}`}
                  onChange={handleOnCheckboxClick}
                  hideLabel
                />
              ) : (
                <RadioInput
                  onChange={handleOnRadioChange}
                  name="item"
                  label=""
                  id={product.isbnLaunch}
                  value={product.isbnLaunch}
                  checked={isChecked}
                />
              )}
            </div>
          )}
          <div className={styles.broken}>
            <img src={product.cover} alt="" onError={addFallbackCover} />
          </div>
        </div>
        <div className={isHubEntry ? classNames(styles.label, styles.oxfordHubItem) : styles.label}>
          {isFromPack && <p className={styles.pack}>Pack ISBN: {product.isbnLicense}</p>}
          <p className={styles.title}>{product.title}</p>

          {!isHubEntry && <HubLabel customClassName={styles.confirmSingleLabel} text={targetUserType} />}

          {isChecked && isAddGradeItemsToggleVisible && product.elt_gradebook_type && (
            <div className={styles.toggle}>
              <Toggle
                toggled={(isChecked || alwaysShowToggles) && isAddGradesChecked}
                onToggle={() => onAddGradeItemsToggleChange(product)}
                disabled={!lmsConfig.gradeSyncEnabled}
              />
              <p className={styles.toggleTitle}>Add grades</p>
            </div>
          )}

          {isChecked && isAddGradesChecked && (
            <div className={styles.gradesToggleInfo}>
              <p>All grades will be added to your course - {product.activitiesNo || 0}</p>
            </div>
          )}

          {isChecked && isAddContentItemsToggleVisible && (
            <div className={styles.toggle}>
              <Toggle
                toggled={(isChecked || alwaysShowToggles) && isAddDeeplinksChecked}
                onToggle={() => onAddContentItemsToggleChange(product)}
                disabled={
                  !lmsConfig.acceptMultiple ||
                  product.unitsWithLessons?.length === 0 ||
                  (product.unitsWithLessons?.length === 1 && product.userType === 'TEACHER') ||
                  product.olbDetails?.endPage === null ||
                  product.olbDetails?.startPage === null
                }
              />
              <p className={styles.toggleTitle}>{linkDescription}</p>
            </div>
          )}
          {((product.platform === PLATFORMS.OLB && !product.olbDetails.startPage && !product.olbDetails.endPage) ||
            (product.platform === PLATFORMS.VST &&
              (product.unitsWithLessons.length === 0 ||
                (product.userType === 'TEACHER' && product.unitsWithLessons.length === 1)))) && (
            <div className={styles.errorMessage}>
              <ValidationMessage state="information">
                {product.platform === PLATFORMS.OLB &&
                  'Error, cannot access page data, adding links to individual pages is currently not available, try again later.'}
                {product.platform === PLATFORMS.VST &&
                  'Error, cannot access activity data, adding links to individual activities is currently not available, try again later.'}
              </ValidationMessage>
            </div>
          )}

          {((isChecked &&
            lmsConfig.acceptMultiple &&
            !isAddDeeplinksChecked &&
            (product.platform === PLATFORMS.VST || product.platform === PLATFORMS.OLB)) ||
            ((isChecked || !alwaysShowToggles) &&
              !lmsConfig.acceptMultiple &&
              !isAddDeeplinksChecked &&
              (product.platform === PLATFORMS.VST || product.platform === PLATFORMS.OLB))) && (
            <div className={styles.toggleInfo}>
              <p>
                <span>1</span> Product link
              </p>
            </div>
          )}

          {isChecked && isAddDeeplinksChecked && (
            <>
              {product.platform === PLATFORMS.VST && (
                <>
                  <div className={styles.toggleInfo}>
                    <p>Links</p>
                  </div>
                  <VSTLinksContainer
                    confirmedProducts={confirmedProducts}
                    onUnitCheckboxClick={handleOnUnitCheckboxClick}
                    product={product}
                  />
                </>
              )}
              {product.platform === PLATFORMS.OLB && currentConfirmedProduct && (
                <div className={styles.toggleInfo}>
                  <div className={styles.toggleInfo}>
                    <p>Links</p>
                  </div>
                  <div>
                    <div className={styles.pages}>
                      <span className={hasErrors ? styles.totalPageNumberTwoFigures : ''}>
                        {!hasErrors ? totalPageNumber : <IconErrorCircle className={styles.validationIcon} />}
                      </span>
                      <div className={styles.pageValueDescriptionContainer}>
                        Select pages between{' '}
                        <div className={styles.pageValue}>{currentConfirmedProduct.olbDetails.startPage}</div> and{' '}
                        <div className={styles.pageValue}>{currentConfirmedProduct.olbDetails.endPage}</div>
                      </div>
                    </div>
                    <div className={styles.pageSelectorContainer}>
                      <div className={styles.paddingLeftObject} />
                      <TextInput
                        onChange={newValue => handleChangePageSelector(START_PAGE, newValue)}
                        id={`start_page_selector_${currentConfirmedProduct.isbnLaunch}`}
                        label="Start page"
                        type="number"
                        name="start_page_input"
                        min={currentConfirmedProduct.olbDetails.startPage}
                        max={currentConfirmedProduct.olbDetails.endPage}
                        value={currentConfirmedProduct.selectedStartPage}
                        isValid={currentConfirmedProduct.errors.START_PAGE_SELECTOR ? false : undefined}
                        validationMessage={currentConfirmedProduct.errors.START_PAGE_SELECTOR}
                      />
                      <TextInput
                        onChange={newValue => handleChangePageSelector(END_PAGE, newValue)}
                        id={`end_page_selector_${currentConfirmedProduct.isbnLaunch}`}
                        label="End page"
                        type="number"
                        name="end_page_input"
                        min={currentConfirmedProduct.olbDetails.startPage}
                        max={currentConfirmedProduct.olbDetails.endPage}
                        value={currentConfirmedProduct.selectedEndPage}
                        isValid={currentConfirmedProduct.errors.END_PAGE ? false : undefined}
                        validationMessage={currentConfirmedProduct.errors.END_PAGE}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.dropDownButton}>
        <HubDropdownMenu
          parentId={product.isbnLicense}
          dropdownTop={false}
          customClassname={styles.iconMenu}
          dropdownData={dropdownActions}
          panelCustomClassname={styles.menu}
        />
      </div>
    </li>
  );
}

ConfirmationSingleItem.propTypes = {
  product: PropTypes.object,
  isChecked: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  alwaysShowToggles: PropTypes.bool,
  isAddDeeplinksChecked: PropTypes.bool,
  isAddGradesChecked: PropTypes.bool,
  onRadioChange: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  onAddContentItemsToggleChange: PropTypes.func,
  onAddGradeItemsToggleChange: PropTypes.func
};

export default ConfirmationSingleItem;
